import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Cell,
  EmptyState,
  FloatingHelper,
  FloatingNotification,
  FormField,
  Input,
  Layout,
  Loader,
  Page,
  Table,
  Notification,
  ToggleSwitch,
  WixDesignSystemProvider,
  StatisticsWidget,
  StarsRatingBar,
  Text,
  Thumbnail,
  BadgeSelect,
  Tooltip,
  TableToolbar,
  Dropdown,
  Search,
  CounterBadge,
  AnnouncementModalLayout,
  Modal,
  SectionHelper,
  Divider,
} from "@wix/design-system";
import axios from "axios";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import * as Definitions from "./definitions";
import { ReactTagManager } from "react-gtm-ts";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import "./App.css";
import { Badge } from "@wix/design-system";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Display 1000 reviews on your site",
  "Automatic updates every hour",
  "Priority Support",
];

const INTERVAL = 4000;

const PLAN_LIMIT: Record<string, number> = {
  basic: 50,
  lite: 100,
  plus: 250,
  pro: 1000,
  elite: 5000,
};

function App() {
  const { t, i18n } = useTranslation();
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");

  const [appId, setAppId] = useState(null as any);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentApp, setCurrentApp] = useState(null as any);

  const api = useRef(null as any);
  const ref = useRef<HTMLElement>(null);

  const [profile, setProfile] = useState(null as any);
  const [reviews, setReviews] = useState(null as any);
  const [settings, setSettings] = useState(null as any);
  const [instanceData, setInstanceData] = useState(null as any);
  const [showFloatingHelper, setShowFloatingHelper] = useState(false);

  const [showPostConnected, setShowPostConnected] = React.useState(false);

  const [showProfileCreatedNotification, setShowProfileCreatedNotification] =
    useState(false);
  const [showSettingSavedNotification, setShowSettingSavedNotification] =
    useState(false);
  const [
    showSettingSaveFailedNotification,
    setShowSettingSaveFailedNotification,
  ] = useState(false);

  const isFree = instanceData?.instance?.isFree !== false;

  const [profileUrl, setProfileUrl] = useState(null as any);

  const [reviewsFilterVisibility, setReviewsFilterVisibility] = useState("0");
  const [reviewsFilterStars, setReviewsFilterStars] = useState("0");
  const [index, setIndex] = useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    useState<boolean>(isFree);

  // Add new state for pinned widget loading
  const [isPinnedWidgetLoading, setIsPinnedWidgetLoading] = useState(true);
  const [isPinnedWidgetEnabled, setIsPinnedWidgetEnabled] = useState(false);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  // Check conditions for showing the floating helper
  useEffect(() => {
    if (
      reviewsFilterVisibility === "0" &&
      reviews?.length &&
      reviews.every((r: any) => !r.status) &&
      !settings?.helperDismissed
    ) {
      setShowFloatingHelper(true);
    } else {
      setShowFloatingHelper(false);
    }
  }, [reviews, reviewsFilterVisibility, settings?.helperDismissed]);

  useEffect(() => {
    const _id = window.location.pathname.split("/")[1];
    const isExist = Definitions.Apps[_id];
    if (isExist) {
      setAppId(window.location.pathname.split("/")[1]);
    }
  }, []);

  useEffect(() => {
    setIsLoaded(true);
    if (appId) {
      setCurrentApp(Definitions.Apps[appId]);
    }
  }, [appId]);

  useEffect(() => {
    if (currentApp) {
      if (token) {
        window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${appId}&redirectUrl=${currentApp.endpoint}/_functions/@certifiedcode/base-backend/auth`;
      }

      const instance = new URLSearchParams(window.location.search).get(
        "instance"
      );

      if (instance) {
        if (api.current) {
          api.current = null;
        }

        const newApiInstance = axios.create({
          baseURL: currentApp.endpoint,
          headers: {
            Authorization: instance || "",
          },
        });

        api.current = newApiInstance;

        setIntercomIdentity();
        getCurrentInstance();
        getProfile();
        getSettings();
      }
    }
  }, [currentApp]);

  useEffect(() => {
    if (settings?._save === true) {
      delete settings._save;
      updateSettings();
    }
  }, [settings]);

  useEffect(() => {
    const reloader = setInterval(() => {
      if (profile?.crawl_status !== "complete") {
        getProfile();
      }
    }, 30000);
    return () => clearInterval(reloader);
  }, []);

  useEffect(() => {
    if (ref) {
      ref.current?.scroll(0, 0);
    }
  }, [ref]);

  // Add new effect to check initial pinned widget status
  useEffect(() => {
    if (api.current) {
      checkPinnedWidgetStatus();
    }
  }, [api.current]);

  function createProfile() {
    var blocks =
      PLAN_LIMIT[instanceData?.instance?.billing?.packageName || "basic"] / 5;
    if (
      profile?.review_count + blocks >
      PLAN_LIMIT[instanceData?.instance?.billing?.packageName || "basic"]
    ) {
      blocks =
        PLAN_LIMIT[instanceData?.instance?.billing?.packageName || "basic"];
    }
    api.current
      .post(
        "/_functions/@certifiedcode/base-reviews-backend/v1/createProfile",
        [
          {
            blocks,
            diff: profile?.status === "complete" ? profile?.job_id : null,
            priority_job:
              instanceData?.instance?.billing?.packageName === "elite",
            ...(appId === "b9425426-51b8-45ac-90e6-7d557bcc65a3"
              ? profileUrl.split(" ").length > 1
                ? {
                  query: profileUrl,
                  }
                : {
                    place_id: profileUrl,
                  }
              : { url: profileUrl }),
          },
        ]
      )
      .then((response: any) => {
        if (
          response?.data?.type === "profile" &&
          response?.data?.object?.success === true
        ) {
          !instanceData?.instance?.billing?.packageName &&
            setShowPostConnected(true);

          setShowProfileCreatedNotification(true);
          getProfile();
        }
      })
      .catch(() => {});
  }

  function getProfile() {
    api.current
      .post("/_functions/@certifiedcode/base-reviews-backend/v1/getProfile", [])
      .then((response: any) => {
        if (
          response?.data?.type === "profile" &&
          !response?.data?.object?.sample
        ) {
          setProfile(response.data.object);
          setProfileUrl(response?.data?.object?.source_url);
          getReviews();
        } else {
          // Start Intercom tour when no profile is found
          window.Intercom("startTour", 589977);
        }
      })
      .catch(() => {
        // Start Intercom tour on error as well since profile would be null
        window.Intercom("startTour", 589977);
      });
  }

  function getSettings() {
    api.current
      .post(
        "/_functions/@certifiedcode/base-reviews-backend/v1/getSettings",
        []
      )
      .then((response: any) => {
        if (response?.data?.type === "settings") {
          setSettings(response.data.object);
        }
      })
      .catch(() => {});
  }

  function updateSettings() {
    api.current
      .post(
        "/_functions/@certifiedcode/base-reviews-backend/v1/updateSettings",
        [settings]
      )
      .then((response: any) => {
        if (response?.data?.type === "settings") {
          setSettings(response.data.object);
          setShowSettingSavedNotification(true);
        } else {
          setShowSettingSaveFailedNotification(true);
        }
      })
      .catch(() => {
        setShowSettingSaveFailedNotification(true);
      });
  }

  function updateReview(reviewId: string, options: any) {
    api.current
      .post("/_functions/@certifiedcode/base-reviews-backend/v1/updateReview", [
        reviewId,
        options,
      ])
      .then((response: any) => {
        if (response?.data?.type === "review") {
          setReviews(
            reviews.map((item: any) => {
              if (item._id === reviewId) {
                return response.data.object;
              }
              return item;
            })
          );
        }
      })
      .catch(() => {});
  }

  function getReviews() {
    api.current
      .post(
        "/_functions/@certifiedcode/base-reviews-backend/v1/getAllReviews",
        []
      )
      .then((response: any) => {
        if (response?.data?.type === "reviews") {
          setReviews(response.data.reviews);
        }
      })
      .catch(() => {});
  }

  function setIntercomIdentity() {
    api.current
      .get("/_functions/@certifiedcode/base-backend/intercom")
      .then((response: any) => {
        if (response.data.hash && response.data.email) {
          window.Intercom("boot", {
            email: response.data.email,
            user_hash: response.data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function getCurrentInstance() {
    api.current
      .get("/_functions/@certifiedcode/base-backend/instance")
      .then((response: any) => {
        if (response.data) {
          setInstanceData(response.data);
          setIsUpgradeBannerOpen(response.data?.instance?.isFree !== false);
        }
      })
      .catch(() => {});
  }

  // Update the checkPinnedWidgetStatus function to handle loading state
  function checkPinnedWidgetStatus() {
    setIsPinnedWidgetLoading(true);
    api.current
      .get("/_functions/@certifiedcode/base-reviews-backend/island")
      .then(() => {
        setIsPinnedWidgetEnabled(true);
        setIsPinnedWidgetLoading(false);
      })
      .catch((error: any) => {
        if (error.response?.status === 404) {
          setIsPinnedWidgetEnabled(false);
        }
        setIsPinnedWidgetLoading(false);
      });
  }

  // Update the togglePinnedWidget function to handle loading state
  function togglePinnedWidget(enabled: boolean) {
    setIsPinnedWidgetLoading(true);
    if (enabled) {
      api.current
        .post("/_functions/@certifiedcode/base-reviews-backend/island")
        .then(() => {
          setIsPinnedWidgetEnabled(true);
          setIsPinnedWidgetLoading(false);
        })
        .catch(() => {
          setIsPinnedWidgetLoading(false);
        });
    } else {
      api.current
        .delete("/_functions/@certifiedcode/base-reviews-backend/island")
        .then(() => {
          setIsPinnedWidgetEnabled(false);
          setIsPinnedWidgetLoading(false);
        })
        .catch(() => {
          setIsPinnedWidgetLoading(false);
        });
    }
  }

  if (!isLoaded) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loader")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  if (!appId) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader status="error" text={t("error-message")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const reviewsColumns = [
    {
      title: t("title-review-1"),
      render: (row: any) => (
        <Box direction="vertical" gap="3px">
          <Text
            ellipsis={true}
            maxLines={1}
            showTooltip={false}
            weight="normal"
          >
            {row.review_title}
          </Text>
          <Tooltip content={row.review_text}>
            <Text
              ellipsis={true}
              maxLines={2}
              showTooltip={false}
              size="small"
              secondary
            >
              {row.review_text}
            </Text>
          </Tooltip>
        </Box>
      ),
      width: "300px",
    },
    {
      title: t("title-reviwer-2"),
      render: (row: any) =>
        row.name ? (
          <Tooltip content={row.name}>{row.name}</Tooltip>
        ) : (
          <Text skin="disabled">Not available</Text>
        ),
      width: "100px",
    },
    {
      title: t("title-stars"),
      render: (row: any) => (
        <StarsRatingBar readOnly size="small" value={row.rating_value} />
      ),
      width: "100px",
    },
    {
      title: t("title-reviewed-on"),
      render: (row: any) => row.date,
      width: "100px",
    },
    {
      title: t("title-visibility"),
      render: (row: any) => (
        <BadgeSelect
          size="small"
          onSelect={(item: any) => {
            updateReview(row._id, {
              status: item.id === "AUTO" ? null : item.id,
            });
          }}
          selectedId={row.status ?? "AUTO"}
          options={[
            {
              id: "AUTO",
              skin: "neutralStandard",
              suffix: <Icons.AIFilledSmall />,
              text: t("title-visibility-option-auto-text"),
              subtitle: t("title-visibility-option-auto-subtext"),
            },
            {
              id: "PUBLISHED",
              skin: "success",
              suffix: <Icons.VisibleSmall />,
              text: t("title-visibility-option-published-text"),
              subtitle: t("title-visibility-option-published-subtext"),
            },
            {
              id: "HIDDEN",
              skin: "neutral",
              suffix: <Icons.HiddenSmall />,
              text: t("title-visibility-option-published-text"),
              subtitle: t("title-visibility-option-published-subtext"),
            },
          ]}
        />
      ),
    },
  ];

  const renderStarSelector = (value: number) => {
    return (
      <Thumbnail
        size="small"
        title={
          <Text>
            {t(value > 1 ? "starTitle_plural" : "starTitle", { count: value })}
          </Text>
        }
        selected={settings?.stars?.includes(value) === true ? true : false}
        onClick={() => {
          if (settings?.stars?.includes(value) === true) {
            setSettings({
              ...settings,
              stars: (settings.stars || []).filter(
                (item: any) => item !== value
              ),
              _save: true,
            });
          } else {
            setSettings({
              ...settings,
              stars: [...(settings.stars || []), value],
              _save: true,
            });
          }
        }}
      >
        {/* <Proportion aspectRatio={1}> */}
        <Box verticalAlign="middle" align="center">
          {/* @ts-ignore */}
          <StarsRatingBar size="tiny" readOnly value={value} />
        </Box>
        {/* </Proportion> */}
      </Thumbnail>
    );
  };

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t("loader")} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const URL = `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${instanceData?.instance?.instanceId}`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification
        autoHideTimeout={3000}
        show={showProfileCreatedNotification}
      >
        <Notification.TextLabel>
          {t("connection-message")}
        </Notification.TextLabel>
        <Notification.CloseButton
          onClick={() => {
            setShowProfileCreatedNotification(false);
          }}
        />
      </Notification>
      <Notification autoHideTimeout={3000} show={showSettingSavedNotification}>
        <Notification.TextLabel>
          {t("update-save-message")}
        </Notification.TextLabel>
      </Notification>
      <Notification
        autoHideTimeout={3000}
        show={showSettingSaveFailedNotification}
      >
        <Notification.TextLabel>
          {t("update-error-message")}
        </Notification.TextLabel>
      </Notification>

      <Notification
        theme="premium"
        show={isUpgradeBannerOpen && isFree}
        type={"sticky"}
      >
        <Notification.TextLabel>
          {t("upgradeMessage", { content: t(`content.${index}`) })}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(URL, "_blank");
          }}
        >
          {t("upgrade-button")}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>

      <Page
        minWidth={0}
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={t("appReviewsTitle", { appName: currentApp?.name })}
          actionsBar={
            <Box direction="horizontal" gap="SP2">
              <Button
                skin={isFree ? "premium" : "premium-light"}
                prefixIcon={<Icons.PremiumFilled />}
                href={URL}
                target="_blank"
                as="a"
              >
                {isFree ? t("upgrade-button") : t("manage-plan")}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            {profile && profile?.crawl_status === "completed" && (
              <Cell span={12}>
                <Card>
                  <Card.Header title={t("card-stats-heading")} />
                  <Card.Divider />
                  <Card.Content>
                    <StatisticsWidget
                      size="tiny"
                      items={[
                        {
                          value: `${profile?.average_rating}/5`,
                          description: t("overall-rating"),
                        },
                        {
                          value: `${profile?.review_count}`,
                          description: t("number-of-reviews"),
                        },
                        {
                          value: `${profile?.last_crawl}`,
                          description: t("last-synced"),
                        },
                      ]}
                    />
                  </Card.Content>
                </Card>
              </Cell>
            )}
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={t("connect-app-title", { appName: currentApp?.name })}
                  suffix={
                    currentApp?.urls?.profile && (
                      <Button
                        skin="light"
                        size="tiny"
                        suffixIcon={<Icons.ExternalLinkSmall />}
                        as="a"
                        target="_blank"
                        href={currentApp?.urls?.profile}
                      >
                        {t("steps-app-title", { appName: currentApp?.name })}
                      </Button>
                    )
                  }
                />
                {/* <Card.Divider /> */}
                {profile?.crawl_status === "pending" && (
                  <FloatingNotification
                    fullWidth
                    type="standard"
                    showCloseButton={false}
                    text={t("fetching-message", { appName: currentApp?.name })}
                  />
                )}
                {profile?.crawl_status === "complete" && (
                  <FloatingNotification
                    fullWidth
                    type="success"
                    showCloseButton={false}
                    text={t("fetching-success-message", {
                      appName: currentApp?.name,
                    })}
                  />
                )}
                {profile?.crawl_status === "maintenance" && (
                  <FloatingNotification
                    fullWidth
                    type="warning"
                    showCloseButton={false}
                    text={t("fetching-error-message", {
                      appName: currentApp?.name,
                    })}
                    buttonProps={{
                      label: t("fetching-error-message-label"),
                      onClick: () => {
                        // intercom
                      },
                    }}
                  />
                )}
                {profile?.crawl_status === "invalid_url" && (
                  <FloatingNotification
                    fullWidth
                    type="destructive"
                    showCloseButton={false}
                    text={t("inavlid-url-text")}
                    buttonProps={{
                      label: t("fetching-error-message-label"),
                      onClick: () => {
                        // intercom
                      },
                    }}
                  />
                )}
                {profile?.crawl_status === "failed" && (
                  <FloatingNotification
                    fullWidth
                    type="destructive"
                    showCloseButton={false}
                    text={t("fetching-failed-message", {
                      appName: currentApp?.name,
                    })}
                    buttonProps={{
                      label: t("fetching-error-message-label"),
                      onClick: () => {
                        // intercom
                      },
                    }}
                  />
                )}
                <Card.Content>
                  <FormField
                    label={t("fetching-failed-message", {
                      appName: currentApp?.name,
                    })}
                  >
                    <Layout>
                      <Cell span={8}>
                        <Input
                          value={profileUrl}
                          onChange={(e: any) => {
                            setProfileUrl(e.target.value);
                          }}
                          // placeholder url can be found at currentApp.placeholders.url
                          placeholder={`e.g. ${currentApp?.placeholders?.url}`}
                          // disableEditing={profile?.crawl_status === "pending"}
                        />
                      </Cell>
                      <Cell span={4}>
                        <Button
                          prefixIcon={<Icons.AIFilled />}
                          skin="ai"
                          disabled={
                            //     profile?.crawl_status === "pending" ||
                            !profileUrl || profile?.source_url === profileUrl
                          }
                          onClick={() => {
                            createProfile();
                          }}
                        >
                          {profile?.source_url
                            ? t("update-button")
                            : t("connect-button")}
                        </Button>
                      </Cell>
                      {currentApp?.placeholders?.example && (
                        <Cell span={12}>
                          <Text size="small" skin="disabled">
                            Examples: {currentApp?.placeholders?.example}
                          </Text>
                        </Cell>
                      )}
                    </Layout>
                  </FormField>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={6}>
              <Card>
                <Card.Header
                  title={t("widget-title")}
                  suffix={
                    <Text size="tiny" skin="disabled">
                      {t("widget-below-text")}
                    </Text>
                  }
                />
                <Card.Divider />
                <Card.Content>
                  <Layout>
                    {/* Add new toggle for pinned widget */}
                    <Cell span={12}>
                      <Box direction="horizontal" gap="SP1">
                        <Text>{"Show Pinned Widget"}</Text>
                        <Badge skin="warningLight" size="small">
                          {"BETA"}
                        </Badge>
                      </Box>
                    </Cell>
                    <Cell span={4}>
                      {isPinnedWidgetLoading ? (
                        <Box align="left">
                          <Loader size="tiny" />
                        </Box>
                      ) : (
                        <Tooltip
                          content={
                            !currentApp?.pinnedWidget?.enabled
                              ? "Coming soon! Stay tuned!"
                              : "Display reviews on your site in the bottom-right corner on all pages"
                          }
                          disabled={currentApp?.pinnedWidget?.enabled}
                        >
                            <ToggleSwitch
                              data-hook="pinned-widget-toggle-switch"
                              size="large"
                              disabled={!currentApp?.pinnedWidget?.enabled}
                              checked={isPinnedWidgetEnabled}
                              onChange={(e: any) => {
                                togglePinnedWidget(e.target.checked);
                              }}
                            />
                        </Tooltip>
                      )}
                    </Cell>
                    {isPinnedWidgetEnabled && (
                      <Cell span={12}>
                        <SectionHelper
                          appearance="success"
                          title="Pinned Widget is enabled. View it from live site."
                          actionText="Share feedback"
                          onAction={() => {
                            window.Intercom(
                              "showNewMessage",
                              "I want to share feedback about the Pinned Widget"
                            );
                          }}
                        />
                      </Cell>
                    )}
                    <Cell span={12}>
                      <Text>
                        You can always add widget to your site by going to the
                        editor and adding the widget to your site.
                      </Text>
                      <Button
                        skin="light"
                        size="tiny"
                        prefixIcon={<Icons.ExternalLinkSmall />}
                        as="a"
                        target="_blank"
                        href="https://support.certifiedcode.us/en/articles/9007330-steps-resolving-missing-widgets-from-the-editor#h_b5cab269f6"
                      >
                        {"Learn more"}
                      </Button>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={6}>
              <Card>
                <Card.Header
                  title={"Reviews Preferences"}
                  suffix={
                    <Text size="tiny" skin="disabled">
                      {t("widget-below-text")}
                    </Text>
                  }
                />
                <Card.Divider />
                <Card.Content>
                  <Layout>
                    <Cell span={12}>
                      <Text> {t("always-show-rating-text")} </Text>
                    </Cell>
                    <Cell span={2}>{renderStarSelector(1)}</Cell>
                    <Cell span={2}>{renderStarSelector(2)}</Cell>
                    <Cell span={2}>{renderStarSelector(3)}</Cell>
                    <Cell span={2}>{renderStarSelector(4)}</Cell>
                    <Cell span={2}>{renderStarSelector(5)}</Cell>
                    <Cell span={12}>
                      <Text>{t("show-default-review-text")}</Text>
                    </Cell>
                    <Cell span={4}>
                      <>
                        {showFloatingHelper && (
                          <FloatingHelper
                            target={<div data-hook="reviews-toggle-switch" />}
                            initiallyOpened
                            placement="bottom"
                            content={
                              <FloatingHelper.Content
                                title={"No reviews visible"}
                                body={
                                  "No reviews are visible because you have not published any reviews yet. You can turn on auto-publish to automatically publish reviews."
                                }
                              />
                            }
                            onClose={() => {
                              setShowFloatingHelper(false);
                              setSettings({
                                ...settings,
                                helperDismissed: true,
                                _save: true,
                              });
                            }}
                          />
                        )}
                        <ToggleSwitch
                          data-hook="reviews-toggle-switch"
                          size="large"
                          checked={settings?.auto_publish === true}
                          onChange={(e: any) => {
                            setSettings({
                              ...settings,
                              auto_publish: e.target.checked,
                              _save: true,
                            });
                          }}
                        />
                      </>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                {reviews && (
                  <>
                    <Table
                      data-hook="reviews-table"
                      columns={reviewsColumns}
                      data={reviews
                        .filter((item: any) => {
                          if (reviewsFilterVisibility === "0") {
                            return true;
                          }
                          if (reviewsFilterVisibility === "1") {
                            return item.status === null;
                          }
                          if (reviewsFilterVisibility === "2") {
                            return item.status === "PUBLISHED";
                          }
                          if (reviewsFilterVisibility === "3") {
                            return item.status === "HIDDEN";
                          }
                          return true;
                        })
                        .filter((item: any) => {
                          if (reviewsFilterStars === "0") {
                            return true;
                          }
                          return (
                            item.rating_value === Number(reviewsFilterStars)
                          );
                        })}
                      horizontalScroll
                      stickyColumns={1}
                    >
                      <TableToolbar>
                        <TableToolbar.ItemGroup>
                          <TableToolbar.Item>
                            <TableToolbar.Title>
                              {t("reviews-heading")}
                            </TableToolbar.Title>
                            <CounterBadge>{reviews?.length}</CounterBadge>
                          </TableToolbar.Item>
                        </TableToolbar.ItemGroup>
                        {/* <TableToolbar.ItemGroup>
                          <TableToolbar.Item>
                            <FormField labelPlacement="left" label="Filter by visibility">
                              <Dropdown
                                size="small"
                                border="round"
                                selectedId={reviewsFilterVisibility}
                                onSelect={(item) => {
                                  setReviewsFilterVisibility(String(item.id));
                                }}
                                options={[
                                  { id: "0", value: "All" },
                                  { id: "1", value: "Auto" },
                                  { id: "2", value: "Published" },
                                  { id: "3", value: "Hidden" },
                                ]}
                              />
                            </FormField>
                          </TableToolbar.Item>
                          <TableToolbar.Item>
                            <FormField labelPlacement="left" label="Filter by stars">
                              <Dropdown
                                size="small"
                                border="round"
                                selectedId={reviewsFilterStars}
                                onSelect={(item) => {
                                  setReviewsFilterStars(String(item.id));
                                }}
                                options={[
                                  { id: "0", value: "All" },
                                  { id: "1", value: "1 Star" },
                                  { id: "2", value: "2 Stars" },
                                  { id: "3", value: "3 Stars" },
                                  { id: "4", value: "4 Stars" },
                                  { id: "5", value: "5 Stars" },
                                ]}
                              />
                            </FormField>
                          </TableToolbar.Item>
                        </TableToolbar.ItemGroup>
                        <TableToolbar.ItemGroup>
                          <TableToolbar.Item>
                            <Search size="small" />
                          </TableToolbar.Item>
                        </TableToolbar.ItemGroup> */}
                        <TableToolbar.ItemGroup>
                          <TableToolbar.Item>
                            <Box gap="SP1">
                              {profile.review_count > reviews.length &&
                                PLAN_LIMIT[
                                  instanceData?.instance?.billing
                                    ?.packageName || "basic"
                                ] > reviews.length && (
                                  <Button
                                    size="small"
                                    priority="primary"
                                    prefixIcon={<Icons.HistorySmall />}
                                    disabled={
                                      profile?.crawl_status !== "complete"
                                    }
                                    onClick={() => {
                                      createProfile();
                                    }}
                                  >
                                    {t("sync-review-button")}
                                  </Button>
                                )}
                              {PLAN_LIMIT[
                                instanceData?.instance?.billing?.packageName ||
                                  "basic"
                              ] <= reviews.length && (
                                <Button
                                  size="small"
                                  skin="premium"
                                  priority="primary"
                                  prefixIcon={<Icons.PremiumFilledSmall />}
                                  as="a"
                                  target="_blank"
                                  href={`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${instanceData?.instance?.instanceId}`}
                                >
                                  {t("upgrade-sync-review-button")}
                                </Button>
                              )}
                            </Box>
                          </TableToolbar.Item>
                        </TableToolbar.ItemGroup>
                      </TableToolbar>
                      <Table.Content titleBarVisible={false} />
                    </Table>
                  </>
                )}
                {!reviews && (
                  <EmptyState
                    theme="page-no-border"
                    title={t("no-review-title")}
                    subtitle={t("no-review-subtitle")}
                  >
                    <Button
                      prefixIcon={<Icons.Edit />}
                      as="a"
                      target="_blank"
                      href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                    >
                      {t("edit-site-button")}
                    </Button>
                  </EmptyState>
                )}
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
      <Modal isOpen={showPostConnected} shouldCloseOnOverlayClick={true}>
        <AnnouncementModalLayout
          theme="premium"
          illustration={<Icons.Check size="200px" />}
          title={t("sync-review-title")}
          primaryButtonText={t("upgrade-button")}
          primaryButtonOnClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                (instanceData as any)?.instance?.instanceId
              }`,
              "_blank"
            );
          }}
          linkText={t("learn-more-button")}
          linkOnClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${
                (instanceData as any)?.instance?.instanceId
              }`,
              "_blank"
            );
          }}
          onCloseButtonClick={() => {
            setShowPostConnected(false);
          }}
        >
          <Text>
            In the meantime, discover how you can get more reviews by upgrading
            to a Premium plan.
          </Text>
        </AnnouncementModalLayout>
      </Modal>
    </WixDesignSystemProvider>
  );
}

export default App;
